import { useFeatureFlag } from 'configcat-react';
import { useLoadProfileQuery } from 'src/api';

export enum FeatureFlags {
  HYBRID_AVAILABLE = 'hybridAvailable',
  HYBRID_ONLY = 'hybridOnly',
  PROJECT_CREATION_RESTRICTED = 'projectCreationRestricted',
  PLAYGROUND_EDIT_DATASET = 'playgroundEditDataset',
  WORKFLOW_BUILDER = 'workflowBuilder',
  DARK_MODE = 'darkmode',
  HIDE_PLAYGROUND = 'hidePlayground',
  OAUTH_LOGIN_TESTING = 'oauthLoginTesting',
  CONNECTION_LIST_UPDATE = 'connectionlistupdate', // cleanup: https://gretel.atlassian.net/browse/INT-2090
  ENTERPRISE_PROJECT_MGMT = 'enterpriseProjectManagement', // TODO(INT-2230): cleanup
  MAGIC_AUTH_LOGIN = 'magicAuthLogin',
  BUTTON_PALETTE_UPDATE = 'buttonPaletteUpdate',
  NAVIGATOR_SAVED_PROMPTS = 'navigatorSavedPrompts',
  DASHBOARD_EXPERIMENT = 'dashboardExperiment', // AH: Personal experiment for dashboard
  PLAYGROUND_SERVERLESS = 'dedicatedPlayground',
  MODELS_LIST_UPDATE = 'modelsListUpdate', // cleanup: https://gretel.atlassian.net/browse/INT-2383
  WORKFLOW_BUILDER_SOURCE_UPLOAD = 'workflowBuilderSourceUpload',
  WORKFLOW_RUN_LIST_NEW = 'workflowRunsListUpdate',
}

/** A hook that will return a boolean `value` for a feature flag for
 * the logged in User as well as loading status. */
type FeatureFlagHook = () => { value: boolean; loading: boolean };
const hookFactory: (f: FeatureFlags) => FeatureFlagHook = featureFlag => () => {
  const { data: userData } = useLoadProfileQuery();
  const userInfo = userData?.guid
    ? {
        email: userData.email,
        identifier: userData.guid,
        custom: { domain: userData.email.split('@').pop() || '' },
      }
    : undefined;

  const { value, loading } = useFeatureFlag(featureFlag, false, userInfo);

  return { value, loading };
};

export const useHybridFeature = hookFactory(FeatureFlags.HYBRID_AVAILABLE);

export const useOnlyHybridFeature = hookFactory(FeatureFlags.HYBRID_ONLY);

export const useProjectCreationRestricted = hookFactory(
  FeatureFlags.PROJECT_CREATION_RESTRICTED
);

export const usePlaygroundEditDataset = hookFactory(
  FeatureFlags.PLAYGROUND_EDIT_DATASET
);

export const useWorkflowBuilder = hookFactory(FeatureFlags.WORKFLOW_BUILDER);

export const useDarkMode = hookFactory(FeatureFlags.DARK_MODE);

export const useHidePlayground = hookFactory(FeatureFlags.HIDE_PLAYGROUND);

export const useOauthLoginTesting = hookFactory(
  FeatureFlags.OAUTH_LOGIN_TESTING
);

export const useConnectionListUpdate = hookFactory(
  FeatureFlags.CONNECTION_LIST_UPDATE
);

export const useEnterpriseProjectManagement = hookFactory(
  FeatureFlags.ENTERPRISE_PROJECT_MGMT
);

export const useMagicAuthLogin = hookFactory(FeatureFlags.MAGIC_AUTH_LOGIN);

export const useButtonPaletteUpdate = () => {
  const { value, loading } = useFeatureFlag(
    FeatureFlags.BUTTON_PALETTE_UPDATE,
    false
  );

  return { value, loading };
};

export const useNavigatorSavedPrompts = hookFactory(
  FeatureFlags.NAVIGATOR_SAVED_PROMPTS
);

// AH: Experimentation
export const useDashboardExperiment = hookFactory(
  FeatureFlags.DASHBOARD_EXPERIMENT
);

// NOTE: You probably don't want to use this flag on its own.
// Instead, check out the useServerlessTenant hook in Playground.
export const useServerlessPlayground = hookFactory(
  FeatureFlags.PLAYGROUND_SERVERLESS
);

export const useModelsListUpdate = hookFactory(FeatureFlags.MODELS_LIST_UPDATE);

export const useWorkflowBuilderSourceUpload = hookFactory(
  FeatureFlags.WORKFLOW_BUILDER_SOURCE_UPLOAD
);

export const useNewWorkflowRunsList = hookFactory(
  FeatureFlags.WORKFLOW_RUN_LIST_NEW
);
